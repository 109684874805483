import React, { useState } from "react"
import styled from "@emotion/styled"

import Messages from "./messages"
import PriceInstruments from "./price-instruments"
import { useQueryString } from "@capitaria/capitaria-utms"
import { trackUserEvent } from "../../helpers/tracker"

const ContainerHero = styled.div`
  display: flex;
  align-items: center;
`

const WrapperHero = styled.div`
  max-width: 2100px;
  width: 100%;
  background: url("/hero-wide.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  @media (max-width: 1800px) {
    background: url("/hero-desktop.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: -80px;
  }
  @media (max-width: 810px) {
    background: url("/hero-tablet.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: -80px;
  }
  @media (max-width: 424px) {
    background: url("/hero-mobile.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: -80px;
  }
`

const WrapperRegister = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  font-family: "Open Sans";
  max-width: 512px;
  margin-left: 220px;
  padding-top: 170px;
  margin-bottom: 120px;
  @media (max-width: 810px) {
    font-size: 30px;
    margin-left: 40px;
    padding-top: 60px;
    padding-right: 80px;
  }
  @media (max-width: 424px) {
    margin-left: 20px;
    padding-top: 180px;
    margin-bottom: 30px;
    padding-right: 0;
  }
`

const FormRegister = styled.form`
  display: inline-flex;
  @media (max-width: 424px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`

const TitleRegister = styled.h2`
  font-size: 39px;
  line-height: 115.3%;
  font-weight: bold;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  margin: 0 0 20px;
  @media (max-width: 810px) {
    font-size: 30px;
    line-height: 118.1%;
    padding-top: 60px;
    padding-right: 45px;
  }
  @media (max-width: 424px) {
    padding-top: 0px;
    padding-right: 60px;
  }
`

const Description = styled.p`
  max-width: 384px;
  font-size: 20px;
  line-height: 27px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  margin: 0 0 20px;
  @media (max-width: 424px) {
    padding-right: 30px;
  }
`

const InputRegister = styled.input`
  width: 238px;
  height: 48px;
  padding: 14px 16px;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  font-size: 14px;
  line-height: 19px;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b7b7b7;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b7b7b7;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b7b7b7;
  }

  @media (max-width: 424px) {
    width: 280px;
    height: 56px;
    border-radius: 5px;
    margin-bottom: 15px;
  }
`

const ButtonRegister = styled.button`
  color: #ffffff;
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  border: none;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  height: 48px;
  padding: 0 24px;
  margin: 0;
  cursor: pointer;
  & :hover {
    background: linear-gradient(180deg, #30ab76 0%, #46a457 100%);
    transition: 0.3s;
  }
  @media (max-width: 424px) {
    width: 280px;
    border-radius: 5px;
    margin-bottom: 15px;
    height: 56px;
  }
`

const Hero = () => {
  const [email, setEmail] = useState("")
  const queryStringParam = useQueryString().queryString
  const mcOrigen = useQueryString().parsedQueryString.mc_origen

  const onSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    queryStringParam: string
  ) => {
    event.preventDefault()

    const mailFormat = /^\w+([\.\-+]*\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (!email.match(mailFormat)) {
      alert("Email invalido")
      return
    }
    const link = document.createElement("a")
    link.href = `https://cloud.info.capitaria.com/hero-capitaria${queryStringParam}&email=${encodeURIComponent(
      email
    )}`

    trackUserEvent("home:hero:sign-up", { email }, { mc_origen: mcOrigen })

    link.rel = "noopener noreferrer"
    link.target = "_blank"
    link.click()
    link.remove()
  }

  return (
    <ContainerHero>
      <WrapperHero>
        <WrapperRegister>
          <RegisterContainer>
            <TitleRegister>
              CADA NOTICIA,
              <br />
              UNA OPORTUNIDAD
            </TitleRegister>
            <Description>
              En Capitaria te damos acceso online a los mercados financieros y
              te acompañamos identificando oportunidades.
            </Description>
            <FormRegister
              method="GET"
              onSubmit={event => onSubmit(event, queryStringParam)}
            >
              <InputRegister
                type="email"
                placeholder="Ingresa tu email"
                name="email"
                onChange={event => {
                  setEmail(event.target.value)
                }}
              />
              <ButtonRegister className="gtm-btn-cuenta-1" type="submit">
                EMPEZAR AHORA
              </ButtonRegister>
            </FormRegister>
          </RegisterContainer>
        </WrapperRegister>
        <PriceInstruments />
        <Messages />
      </WrapperHero>
    </ContainerHero>
  )
}

export default Hero
