import React from "react"
import styled from "@emotion/styled"

import CustomSlider from "../ui/custom-slider"

const WrapperTestimonials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  color: #ffffff;
  font-family: "Open Sans";
  background: #16191a;
  padding-bottom: 50px;
`

const Header = styled.h2`
  font-size: 26px;
  font-weight: bold;
  max-width: 300px;
  line-height: 118.1%;
  text-align: center;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    font-size: 30px;
    max-width: 100%;
  }
`

const TestimonialWrapper = styled.div`
  width: 310px;
  margin: 0 8px;
  scroll-snap-align: start;
  flex-shrink: 0;
  @media (min-width: 1025px) {
    font-size: 30px;
    padding: 0;
    margin: 0 50px;
  }
`

const Title = styled.h4`
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  text-align: left;
`

const Body = styled.p`
  font-size: 12px;
  line-height: 16px;
  text-align: left;
`

const Author = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  text-align: left;
`

const ContainerRankia = styled.div`
  margin-top: 40px;
  text-align: center;
  > p {
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
  }
  > img {
    width: 345px;
  }
`

type CustomerTestimonial = {
  title: string
  body: string
  author: string
}

const testimonials: CustomerTestimonial[] = [
  {
    title: "Gran Preocupación y comunicación",
    body:
      "Descubrí el Trading buscando hacer algo diferente en inversiones, algo entretenido y desafiante. En Capitaria veo una gran preocupación y comunicación con los clientes.",
    author: "Juan Carlos Matte A.",
  },
  {
    title: "Buenos y Rápidos",
    body:
      "Muy buen funcionamiento de la plataforma, muy rápidos los depósitos y retiros de dinero y muy buena la aplicación del móvil.",
    author: "Carlos Nebot",
  },
  {
    title: "Información constante",
    body:
      "En Capitaria entregan constante información, se nota que las personas que trabajan quieren que te vaya bien y tratan se traspasar el conocimiento de la mejor manera.",
    author: "Kevin Toledo",
  },
]

type Props = {
  data: CustomerTestimonial
}

const Testimonial = ({ data: { title, body, author } }: Props) => (
  <TestimonialWrapper>
    <Title>{title}</Title>
    <Body>{body}</Body>
    <Author>{author}</Author>
  </TestimonialWrapper>
)

const Traders = () => {
  return (
    <WrapperTestimonials>
      <Header>Por qué los traders prefieren Capitaria</Header>

      <CustomSlider dataSlides={testimonials} dark>
        <Testimonial data={testimonials[0]} />
      </CustomSlider>

      <ContainerRankia>
        <p>PREMIOS RANKIA 2020</p>
        <img src="/rankia.png" alt="Rankia" />
      </ContainerRankia>
    </WrapperTestimonials>
  )
}

export default Traders
