import React from "react"
import styled from "@emotion/styled"

const SvgWallet = styled.svg`
  transform: scale(1.1);
`

const Charts = () => (
  <SvgWallet
    width="80"
    height="80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.185 44.24h3.173a.68.68 0 00.68-.68V30.3a.68.68 0 00-.68-.68h-3.173a.68.68 0 00-.68.68v13.26c0 .376.305.68.68.68zm.68-13.26h1.813v11.9h-1.813v-11.9z"
      fill="url(#paint0_linearc)"
    />
    <path
      d="M47.027 44.24H50.2a.68.68 0 00.68-.68V28.713a.68.68 0 00-.68-.68h-3.174a.68.68 0 00-.68.68V43.56c0 .376.304.68.68.68zm.68-14.847h1.814V42.88h-1.814V29.393z"
      fill="url(#paint1_linearc)"
    />
    <path
      d="M38.412 44.24h3.175a.68.68 0 00.68-.68V30.3a.68.68 0 00-.68-.68h-3.175a.68.68 0 00-.68.68v13.26c0 .376.305.68.68.68zm.68-13.26h1.815v11.9h-1.815v-11.9z"
      fill="url(#paint2_linearc)"
    />
    <path
      d="M29.797 44.24h3.174a.68.68 0 00.68-.68v-9.406a.68.68 0 00-.68-.68h-3.174a.68.68 0 00-.68.68v9.406c0 .376.305.68.68.68zm.68-9.406h1.814v8.046h-1.814v-8.046z"
      fill="url(#paint3_linearc)"
    />
    <path
      d="M58.816 31.886h-3.174a.68.68 0 00-.68.68V43.56c0 .376.304.68.68.68h3.174a.68.68 0 00.68-.68V32.566a.68.68 0 00-.68-.68zm-.68 10.994h-1.814v-9.634h1.814v9.634z"
      fill="url(#paint4_linearc)"
    />
    <path
      d="M50.679 46.703c-.024-.023-.055-.033-.08-.052-.037-.027-.071-.054-.112-.073-.042-.02-.084-.029-.128-.04-.04-.01-.08-.02-.122-.022-.044-.003-.087.004-.132.01-.042.007-.082.012-.124.026-.043.014-.08.038-.12.061-.028.016-.06.023-.086.044l-8.224 6.6-8.2-5.5c-.015-.011-.033-.012-.048-.02a.643.643 0 00-.125-.052.666.666 0 00-.128-.032c-.04-.005-.078-.002-.118 0a.673.673 0 00-.143.022c-.016.004-.033 0-.05.007l-11.787 4.307a.68.68 0 10.466 1.277l11.463-4.188 8.327 5.587c.02.014.043.018.064.03.02.01.04.02.06.028a.667.667 0 00.254.058h.001l.004-.001c.066 0 .13-.014.193-.033.019-.005.036-.013.054-.02.051-.02.1-.046.145-.08.01-.007.02-.009.03-.015l8.142-6.534 8.183 8.074a.676.676 0 00.962-.006.68.68 0 00-.007-.962l-8.614-8.5z"
      fill="url(#paint5_linearc)"
    />
    <path
      d="M73.32 15H6.68a.68.68 0 00-.68.68v49.64c0 .376.305.68.68.68h66.64a.68.68 0 00.68-.68V15.68a.68.68 0 00-.68-.68zm-.68 49.64H7.36V16.36h65.28v48.28z"
      fill="url(#paint6_linearc)"
    />
    <path
      d="M9.514 63.394h60.973a.68.68 0 00.68-.68V21.687a.68.68 0 00-.68-.68H9.514a.68.68 0 00-.68.68v41.026c0 .377.305.681.68.681zm.68-41.027h59.613v39.666H10.194V22.367z"
      fill="url(#paint7_linearc)"
    />
    <path
      d="M66.49 20.71a2.03 2.03 0 002.029-2.027 2.03 2.03 0 00-2.028-2.027 2.03 2.03 0 00-2.027 2.027 2.03 2.03 0 002.027 2.028zm0-2.694a.668.668 0 010 1.335.668.668 0 010-1.335z"
      fill="url(#paint8_linearc)"
    />
    <path
      d="M61.4 20.71a2.03 2.03 0 002.027-2.027 2.03 2.03 0 00-2.028-2.027 2.03 2.03 0 00-2.027 2.027c-.001 1.118.909 2.028 2.027 2.028zm0-2.694a.668.668 0 11-.002 1.337.668.668 0 01.001-1.337z"
      fill="url(#paint9_linearc)"
    />
    <path
      d="M56.307 20.71a2.03 2.03 0 002.027-2.027 2.03 2.03 0 00-2.027-2.027 2.03 2.03 0 00-2.028 2.027 2.03 2.03 0 002.028 2.028zm0-2.694a.668.668 0 11-.003 1.336.668.668 0 01.003-1.336z"
      fill="url(#paint10_linearc)"
    />
    <defs>
      <linearGradient
        id="paint0_linearc"
        x1="22.772"
        y1="29.62"
        x2="22.772"
        y2="44.24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint1_linearc"
        x1="48.614"
        y1="28.033"
        x2="48.614"
        y2="44.24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint2_linearc"
        x1="40"
        y1="29.62"
        x2="40"
        y2="44.24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint3_linearc"
        x1="31.384"
        y1="33.474"
        x2="31.384"
        y2="44.24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint4_linearc"
        x1="57.229"
        y1="31.886"
        x2="57.229"
        y2="44.24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint5_linearc"
        x1="40"
        y1="46.515"
        x2="40"
        y2="56.368"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint6_linearc"
        x1="40"
        y1="15"
        x2="40"
        y2="66"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint7_linearc"
        x1="40"
        y1="21.007"
        x2="40"
        y2="63.394"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint8_linearc"
        x1="66.491"
        y1="16.656"
        x2="66.491"
        y2="20.711"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint9_linearc"
        x1="61.399"
        y1="16.656"
        x2="61.399"
        y2="20.711"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint10_linearc"
        x1="56.306"
        y1="16.656"
        x2="56.306"
        y2="20.711"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
    </defs>
  </SvgWallet>
)

export default Charts
