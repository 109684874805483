import React, { useState } from "react"
import styled from "@emotion/styled"

import CustomSlider from "../ui/custom-slider"

import { useQueryString } from "@capitaria/capitaria-utms"
import { trackEvent } from "../../helpers/tracker"

const WrapperLevelKnowledge = styled.div`
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  color: #363f41;
  font-family: "Open Sans";
  padding-top: 80px;
  padding-bottom: 150px;

  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 100px;
  }
  @media (max-width: 414px) {
    padding-top: 15px;
    padding-bottom: 100px;
  }
`

const ContainerLevelKnowledge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`

const TitleLevelKnowledge = styled.h2`
  font-size: 30px;
  line-height: 118.1%;
  font-weight: bold;
  text-align: center;
  padding: 30px 110px;
  margin: 0;
  @media (max-width: 768px) {
    max-width: 472px;
    padding: 40px 20px;
    font-size: 24px;
  }
`

const SubjectLevelKnowledge = styled.h5`
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  @media (max-width: 768px) {
    display: none;
  }
`

const Knowledge = styled.div`
  width: 326px;
  min-height: 234px;
  padding: 1rem;
  margin: 0 8px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 10px;
  transition: box-shadow 1s;
  border: 1px solid #d7d3ff;

  @media (max-width: 768px) {
    margin: 0;
    scroll-snap-align: start;
    flex-shrink: 0;
  }

  & :hover {
    box-shadow: 0px 15px 30px #dde4ee;
    img {
      filter: saturate(0.5);
    }
  }
  &.active {
    box-shadow: 0px 15px 30px #dde4ee;
    img {
      filter: none;
    }
  }
`

const Image = styled.img`
  width: 180px;
  filter: grayscale(100%);
  transition: filter 0.5s;
  & :focus {
    filter: saturate(110%);
  }
`

const Detail = styled.p`
  font-size: 14px;
  line-height: 22px;
  padding: 0 10px;
`

const ContainerDescription = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 50px;
  @media (max-width: 768px) {
    max-width: 530px;
    padding: 0 30px;
  }
  @media (max-width: 414px) {
    max-width: 310px;
    padding: 0;
  }
`

const TitleDescription = styled.h3`
  font-size: 30px;
  font-weight: bold;
  line-height: 118.1%;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const Description = styled.p`
  font-size: 14px;
  line-height: 22px;
`

const TextInvitation = styled(Description)`
  font-weight: bold;
`

const Button = styled.a`
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  border-radius: 5px;
  border: none;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  display: inherit;
  font-size: 14px;
  font-weight: 600;
  margin: 30px auto 0;
  padding: 16px;
  text-decoration: none;
  width: 250px;
  & :hover {
    background: linear-gradient(180deg, #30ab76 0%, #46a457 100%);
    transition: 0.3s;
  }
`

const DescriptionLevel1 = () => {
  const mcOrigen = useQueryString().parsedQueryString.mc_origen

  return (
    <ContainerDescription>
      <TitleDescription>
        ¿Eres nuevo?
        <br />
        No te preocupes
      </TitleDescription>
      <Description>
        El Trading puede parecer confuso al principio, pero estamos aquí para
        ayudarte.{" "}
        <strong>
          En nuestra sección de soporte te guiaremos a través de los conceptos
          básicos.
        </strong>
      </Description>
      <Button
        className="gtm-btn-principiante"
        href="https://capitaria.zendesk.com/hc/es/articles/360043281151-Introducci%C3%B3n-al-Trading"
        target="_blank"
        onClick={() => {
          trackEvent("home:level-knowledge:level1", {
            mc_origen: mcOrigen,
          })
        }}
      >
        APRENDE AQUÍ
      </Button>
    </ContainerDescription>
  )
}

const DescriptionLevel2 = () => {
  const mcOrigen = useQueryString().parsedQueryString.mc_origen

  return (
    <ContainerDescription>
      <TitleDescription>
        ¿Eres nuevo en Capitaria? No hay problema
      </TitleDescription>
      <Description>
        Te damos acceso online al mercado financiero, de manera segura y simple.
        Nuestra plataforma está diseñada tanto para principiantes como para
        expertos
      </Description>
      <TextInvitation>Aprende cómo hacer Trading en Capitaria</TextInvitation>
      <Button
        className="gtm-btn-intermedio"
        href="https://capitaria.zendesk.com/hc/es/sections/360008863471-Trading"
        target="_blank"
        onClick={() => {
          trackEvent("home:level-knowledge:level2", {
            mc_origen: mcOrigen,
          })
        }}
      >
        APRENDE AQUÍ
      </Button>
    </ContainerDescription>
  )
}

const DescriptionLevel3 = () => {
  const mcOrigen = useQueryString().parsedQueryString.mc_origen

  return (
    <ContainerDescription>
      <TitleDescription>Una plataforma. El mejor servicio</TitleDescription>
      <Description>
        Ofrecemos más de 500 instrumentos, depósitos y retiros a la velocidad
        del mercado y un equipo de expertos para ayudarte en lo que necesites.
      </Description>
      <Button
        className="gtm-btn-experto"
        href="https://api.whatsapp.com/send?phone=+56991818258"
        target="_blank"
        onClick={() => {
          trackEvent("home:level-knowledge:level3", {
            mc_origen: mcOrigen,
          })
        }}
      >
        HABLEMOS
      </Button>
    </ContainerDescription>
  )
}

type Knowledge = {
  image: string
  className: string
  detail: React.ReactNode
  description: React.ReactNode
}

const knowledges: Knowledge[] = [
  {
    image: "level-1.png",
    className: "gtm-card-principiante",
    detail: (
      <span>
        <strong>Soy nuevo en el Trading.</strong> Necesito que alguien me
        explique cómo funciona esto
      </span>
    ),
    description: <DescriptionLevel1 />,
  },
  {
    image: "level-2.png",
    className: "gtm-card-intermedio",
    detail: (
      <span>
        <strong>Quiero llevar mis inversiones</strong> al siguiente nivel
      </span>
    ),
    description: <DescriptionLevel2 />,
  },
  {
    image: "level-3.png",
    className: "gtm-card-experto",
    detail: <span>Estoy buscando algo que mi broker actual no me da</span>,
    description: <DescriptionLevel3 />,
  },
]

type LevelProps = {
  data: Knowledge
  active?: boolean
  onClick?: any
}

const Level = ({ active, data, onClick }: LevelProps) => {
  const className = active ? "active" : ""

  return (
    <Knowledge className={`${className} ${data.className}`} onClick={onClick}>
      <Image src={data.image}></Image>
      <Detail>{data.detail}</Detail>
    </Knowledge>
  )
}

const LevelKnowledge = () => {
  const [description, setDescription] = useState(knowledges[0].description)

  return (
    <WrapperLevelKnowledge>
      <ContainerLevelKnowledge>
        <TitleLevelKnowledge>
          No importa tu nivel de conocimiento. Trabajemos juntos para lograr tus
          objetivos
        </TitleLevelKnowledge>

        <SubjectLevelKnowledge>
          ¿Cómo te podemos ayudar a empezar?
        </SubjectLevelKnowledge>

        <CustomSlider
          dataSlides={knowledges}
          onClick={(k: Knowledge) => {
            setDescription(k.description)
          }}
          onScroll={(k: Knowledge) => {
            setDescription(k.description)
          }}
        >
          <Level data={knowledges[0]} />
        </CustomSlider>

        {description}
      </ContainerLevelKnowledge>
    </WrapperLevelKnowledge>
  )
}

export default LevelKnowledge
