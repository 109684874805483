import React from "react"

const Megaphone = () => (
  <svg
    width="38"
    height="27"
    viewBox="0 0 38 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5006 0H24.3399C25.2991 0 26.0185 0.719407 26.0185 1.67862V21.9419C26.0185 22.7812 25.2991 23.5006 24.3399 23.5006H23.5006C22.6613 23.5006 21.9419 22.9011 21.822 22.0618C19.424 19.9036 16.6663 18.5847 13.4289 17.9852L15.8269 25.6588C16.0667 26.4981 15.9468 26.8578 15.2274 26.9777H10.5513C10.1916 27.0976 9.59209 26.7379 9.35229 25.7787L6.83436 17.5056C5.27565 17.5056 4.07664 17.5056 2.87763 16.3065H1.67862C0.719407 16.3065 0 15.5871 0 14.7478V8.75278C0 7.79357 0.719407 7.31397 1.67862 7.31397H2.87763C3.59703 6.47466 4.55624 5.99505 5.51545 5.99505C12.2299 5.99505 16.5464 5.63535 21.822 1.31891C21.9419 0.599505 22.6613 0 23.5006 0ZM29.6156 12.5896C28.7763 12.5896 28.7763 11.1508 29.6156 11.1508H36.8096C37.8887 11.1508 37.8887 12.5896 36.8096 12.5896H29.6156ZM36.0902 20.3832C36.9295 20.9827 35.9703 22.0618 35.2509 21.5822L29.136 16.9061C28.4166 16.3065 29.3758 14.9876 30.0952 15.707L36.0902 20.3832ZM30.0952 8.03337C29.3758 8.75278 28.4166 7.55377 29.136 6.95426L35.2509 2.27812C35.9703 1.55871 36.9295 2.87763 36.0902 3.35723L30.0952 8.03337ZM21.822 20.0235V3.23733C16.5464 7.19407 12.11 7.55377 5.51545 7.55377C4.55624 7.55377 3.71693 8.27318 3.71693 9.47219V14.1483C3.71693 15.1075 4.55624 15.9468 5.51545 15.9468C11.8702 15.9468 16.7862 16.0667 21.822 20.0235ZM23.3807 21.9419H23.5006H24.3399C24.4598 21.9419 24.4598 21.9419 24.4598 21.9419V1.67862C24.4598 1.55871 24.3399 1.55871 24.3399 1.55871H23.5006C23.5006 1.55871 23.3807 1.55871 23.3807 1.67862V21.9419ZM2.15822 8.75278C2.15822 8.75278 1.67862 8.63288 1.43881 8.87268V14.7478C1.43881 14.8677 2.15822 14.7478 2.15822 14.7478V8.75278ZM14.2682 25.5389L11.8702 17.6255C10.4314 17.5056 9.59209 17.5056 8.39308 17.5056L10.7911 25.5389H14.2682Z"
      fill="#71C552"
    />
  </svg>
)

export default Megaphone
