import React, { useEffect, useState } from "react"

import { useQueryString } from "@capitaria/capitaria-utms"
import { trackEvent } from "../../helpers/tracker"

import Traders from "./traders"
import LevelKnowledge from "./level-knowledge"
import WeAreTraders from "./we-are-traders"
import CTA from "./cta"
import Hero from "./hero"
import LeadGenerator from "./lead-generator"

let idSetInterval: NodeJS.Timeout

const HomePage = () => {
  const [pageViewTracked, setPageViewTracked] = useState(false)
  const mcOrigen = useQueryString().parsedQueryString.mc_origen

  if (!pageViewTracked && mcOrigen) {
    const initEvent = () => {
      idSetInterval = setInterval(() => {
        if (
          typeof window !== "undefined" &&
          window.amplitude &&
          // @ts-ignore
          window.amplitude.runQueuedFunctions
        ) {
          trackEvent("home:pageview", { mc_origen: mcOrigen })
          setPageViewTracked(true)
          clearInterval(idSetInterval)
        }
      }, 1000)
    }

    initEvent()
  }

  return (
    <>
      
      <Hero />
      <Traders />
      <LevelKnowledge />
      <WeAreTraders />
      <CTA />
      <LeadGenerator />
    </>
  )
}

export default HomePage
