import React from "react"

import Layout from "../components/ui/layout"
import SEO from "../components/seo"

import { QueryStringProvider } from "@capitaria/capitaria-utms"

import HomePage from "../components/home-page/home-page"

const IndexPage = () => (
  <QueryStringProvider useCountry>
    <Layout>
      <SEO title="CAPITARIA" />

      <HomePage />
    </Layout>
  </QueryStringProvider>
)

export default IndexPage
