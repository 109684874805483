import React from "react"
import styled from "@emotion/styled"

import Wallet from "../ui/images/wallet"
import Clock from "../ui/images/clock"
import People from "../ui/images/people"
import Charts from "../ui/images/charts"

const dataTraders = [
  {
    image: <Wallet />,
    title: "Olvídate de las comisiones",
    subject: "En Capitaria no pagas comisiones ni costos de mantención",
  },
  {
    image: <Clock />,
    title: "Retiros a la velocidad del mercado",
    subject: "98% de los retiros se hacen en menos de 4 horas",
  },
  {
    image: <People />,
    title: "De lobo solitario a ser parte de la manada",
    subject:
      "Únete a nuestra comunidad de Traders y vivamos juntos la adrenalina de los mercados",
  },
  {
    image: <Charts />,
    title: "Comunicación directa",
    className: "two-line",
    subject:
      "Llama a tu equipo de ejecutivos o a la mesa de dinero. Te contestaremos siempre.",
  },
]

const WrapperWeAreTraders = styled.div`
  background: #1f2425;
  color: #ffffff;
  font-family: "Open Sans";
  padding: 100px 0;
  @media (max-width: 768px) {
    padding-top: 73px;
  }
  @media (max-width: 414px) {
    padding-top: 80px;
  }
`

const TraderInfo = styled.div`
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`

const TraderTitle = styled.h2`
  font-size: 30px;
  line-height: 118.1%;
  font-weight: bold;
  @media (max-width: 768px) {
    padding: 20px;
    font-size: 24px;
  }
`

const TraderDescription = styled.h4`
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

const Traders = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1050px;
  margin: 0 auto;
  justify-content: center;
  margin-top: 50px;
  @media (max-width: 1020px) {
    max-width: 540px;
  }
`

const Trader = styled.div`
  width: 250px;
  text-align: center;
  padding: 0 20px;
  margin-bottom: 80px;
`

const Image = styled.div`
  height: 65px;
`

const Title = styled.div`
  font-size: 20px;
  line-height: 27px;
  font-weight: bold;
  margin-top: 10px;
  &.two-line {
    padding: 0 2px;
  }
`

const Subject = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-top: 20px;
`

const WeAreTraders = () => (
  <WrapperWeAreTraders>
    <TraderInfo>
      <TraderTitle>Un Broker hecho por Traders, para Traders</TraderTitle>
      <TraderDescription>
        En 15 años, más de 20.000 traders han confiado en nosotros. Juntos
        movemos más de 2,5 Billones de dólares mensuales
      </TraderDescription>
    </TraderInfo>
    <Traders>
      {dataTraders.map((trader: any, idx) => (
        <Trader key={`trader-${idx}`}>
          <Image>{trader.image}</Image>
          <Title className={trader.className}>{trader.title}</Title>
          <Subject>{trader.subject}</Subject>
        </Trader>
      ))}
    </Traders>
  </WrapperWeAreTraders>
)

export default WeAreTraders
