import React from "react"
import styled from "@emotion/styled"

import CheckIcon from "../ui/images/check-icon"
import { useQueryString } from "@capitaria/capitaria-utms"
import { trackEvent } from "../../helpers/tracker"

const WrapperCTA = styled.div`
  padding-top: 60px;
  background: #16191a;
  color: #ffffff;
  padding-bottom: 100px;
`

const TitleCTA = styled.h2`
  font-size: 30px;
  line-height: 118.1%;
  font-weight: bold;
  max-width: 750px;
  padding: 0 40px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`

const ContainerCheck = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 20px 0;

  @media (max-width: 810px) {
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
  }
  > svg {
    margin: 0 6px;
  }
  > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
`

const Check = styled.div`
  text-align: left;
  width: 176px;
  margin: 40px 0;
  display: flex;
  align-items: center;

  @media (max-width: 810px) {
    margin: 20px 0;
  }
`

const ContainerButton = styled.div``

const Button = styled.a`
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  border-radius: 5px;
  border: none;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  display: inherit;
  font-size: 14px;
  font-weight: 600;
  height: 48px;
  line-height: 16px;
  margin: 0 auto;
  max-width: 250px;
  padding: 16px;
  text-align: center;
  text-decoration: none;
  & :hover {
    background: linear-gradient(180deg, #30ab76 0%, #46a457 100%);
    transition: 0.3s;
  }
`

const Text = styled.span`
  margin-left: 5px;
`

const CTA = () => {
  const queryString = useQueryString().queryString
  const mcOrigen = useQueryString().parsedQueryString.mc_origen

  return (
    <WrapperCTA>
      <TitleCTA>
        Mientras leías esto, un Trader ya encontró una oportunidad. ¿Cuál es tu
        excusa?
      </TitleCTA>
      <ContainerCheck>
        <Check>
          <CheckIcon width="29" height="22" />
          <Text>Crea tu cuenta</Text>
        </Check>
        <Check>
          <CheckIcon width="29" height="22" />
          <Text>Deposita tu capital</Text>
        </Check>
        <Check>
          <CheckIcon width="29" height="22" />
          <Text>Comienza a operar</Text>
        </Check>
      </ContainerCheck>
      <ContainerButton>
        <Button
          className="gtm-btn-cuenta-3"
          href={`https://onboarding.capitaria.com/sign-up${queryString}`}
          onClick={() => {
            trackEvent("home:cta:create-account", {
              mc_origen: mcOrigen,
            })
          }}
        >
          ¡CREA TU CUENTA AHORA!
        </Button>
      </ContainerButton>
    </WrapperCTA>
  )
}

export default CTA
