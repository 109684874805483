import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"

import Megaphone from "../ui/images/megaphone"
import { useQueryString } from "@capitaria/capitaria-utms"
import { trackEvent } from "../../helpers/tracker"

const dataMessages = [
  '"El tweet de Elon Musk hace mover a Wall Street"',
  '"Apple subió 30% después de lanzar el ultimo iPhone"',
]

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 80px;
  @media (min-width: 768px) {
    margin-top: 90px;
    margin-bottom: 120px;
  }
  @media (max-width: 414px) {
    margin-bottom: 90px;
  }
`

const Container = styled.div`
  max-width: 680px;
`

const Paragraph = styled.p`
  font-family: "Open Sans";
  font-weight: 600;
  color: #ffffff;
  min-width: 100%;
`

const PrincipalParagraph = styled(Paragraph)`
  font-size: 30px;
  line-height: 118.1%;
  margin-bottom: 24px;
  @media (max-width: 424px) {
    font-size: 26px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 20px;
  }
`

const SecondaryParagraph = styled(Paragraph)`
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 30px;
  @media (max-width: 424px) {
    margin-top: 50px;
    border-bottom: 0.5px solid #272727;
  }
`

const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans";
  color: #ffffff;
  border: 1px solid #5f5f5f;
  border-radius: 7px;
  max-width: 680px;
  padding: 0 20px;
  @media (max-width: 768px) {
    padding: 15px 20px;
    display: block;
    margin: auto 10px;
  }
`

const MessageIcon = styled.p`
  @media (max-width: 768px) {
    margin: 0;
  }
`

const MessageText = styled.p`
  margin-left: 10px;
  font-size: 16px;
  line-height: 27px;
  @media (max-width: 768px) {
    margin: 0;
  }
  @media (max-width: 414px) {
    font-size: 16px;
  }
`

const Button = styled.a`
  display: inherit;
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  border-radius: 6px;
  border: none;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  padding: 16px 38px;
  width: 240px;
  text-decoration: none;
  margin: 0 auto;
  & :hover {
    background: linear-gradient(180deg, #30ab76 0%, #46a457 100%);
    transition: 0.3s;
  }
`

const Messages = () => {
  const [message, setMessage] = useState("")
  const { queryString } = useQueryString()
  const mcOrigen = useQueryString().parsedQueryString.mc_origen

  useEffect(() => {
    setMessage(dataMessages[Math.floor(Math.random() * dataMessages.length)])
    const interval = setInterval(() => {
      setMessage(dataMessages[Math.floor(Math.random() * dataMessages.length)])
    }, 6000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Wrapper>
      <Container>
        <PrincipalParagraph>
          La vida pasa muy rápido para seguir invirtiendo de forma tradicional
        </PrincipalParagraph>
        <Message>
          <MessageIcon>
            <Megaphone />
          </MessageIcon>{" "}
          {message && <MessageText>{message}</MessageText>}
        </Message>
        <SecondaryParagraph>
          Entra al mercado en un click, sin depender de nadie
        </SecondaryParagraph>
        <Button
          className="gtm-btn-cuenta-2"
          href={`https://onboarding.capitaria.com/sign-up${queryString}`}
          onClick={() => {
            trackEvent("home:messages:create-account", {
              mc_origen: mcOrigen,
            })
          }}
        >
          ¡CREA TU CUENTA AHORA!
        </Button>
      </Container>
    </Wrapper>
  )
}

export default Messages
