import * as Sentry from "@sentry/browser"

const dataPriceInstruments = [
  {
    value: "1",
    name: "Divisas",
    loading: true,
    className: "gtm-p-tab-divisa",
    url:
      "https://5ssi4wo89j.execute-api.us-east-2.amazonaws.com/prod/currencies.php",
    instruments: {
      head: [
        { title: "Nemo", key: "nemo", css: { width: "130px" } },
        { title: "Nombre", key: "name", class: "hide-mobile" },
        { title: "Precio Venta", key: "sell", css: { width: "120px" } },
        { title: "Precio Compra", key: "buy", css: { width: "120px" } },
      ],
      body: [],
    },
  },
  {
    value: "2",
    name: "Acciones",
    loading: true,
    className: "gtm-p-tab-accion",
    url:
      "https://5ssi4wo89j.execute-api.us-east-2.amazonaws.com/prod/stocks.php",
    instruments: {
      head: [
        { title: "Nemo", key: "nemo", css: { width: "130px" } },
        { title: "Nombre", key: "name", class: "hide-mobile" },
        { title: "Precio Venta", key: "sell", css: { width: "120px" } },
        { title: "Precio Compra", key: "buy", css: { width: "120px" } },
      ],
      body: [],
    },
  },
  {
    value: "3",
    name: "ETFs",
    loading: true,
    className: "gtm-p-tab-etf",
    url: "https://5ssi4wo89j.execute-api.us-east-2.amazonaws.com/prod/etfs.php",
    instruments: {
      head: [
        { title: "Nemo", key: "nemo", css: { width: "130px" } },
        { title: "Nombre", key: "name", class: "hide-mobile" },
        { title: "Precio Venta", key: "sell", css: { width: "120px" } },
        { title: "Precio Compra", key: "buy", css: { width: "120px" } },
      ],
      body: [],
    },
  },
  {
    value: "4",
    name: "Commodities",
    loading: true,
    className: "gtm-p-tab-commodity",
    url:
      "https://5ssi4wo89j.execute-api.us-east-2.amazonaws.com/prod/commodities.php",
    instruments: {
      head: [
        { title: "Nemo", key: "nemo", css: { width: "130px" } },
        { title: "Nombre", key: "name", class: "hide-mobile" },
        { title: "Precio Venta", key: "sell", css: { width: "120px" } },
        { title: "Precio Compra", key: "buy", css: { width: "120px" } },
      ],
      body: [],
    },
  },
  {
    value: "5",
    name: "Índices bursátiles",
    loading: true,
    className: "gtm-p-tab-indices",
    url:
      "https://5ssi4wo89j.execute-api.us-east-2.amazonaws.com/prod/stock-index.php",
    instruments: {
      head: [
        { title: "Nemo", key: "nemo", css: { width: "130px" } },
        { title: "Nombre", key: "name", class: "hide-mobile" },
        { title: "Precio Venta", key: "sell", css: { width: "120px" } },
        { title: "Precio Compra", key: "buy", css: { width: "120px" } },
      ],
      body: [],
    },
  },
]

const nameInstruments = {
  "#AMZN": "AMAZON",
  "#GOOGL": "GOOGLE",
  "#AAPL": "APPLE",
  "#FB": "FACEBOOK",
  "#TSLA": "TESLA",
  "#BABA": "ALIBABA",
  BTCUSD: "BITCOIN",
  ETHUSD: "ETHEREUM",
  LTCUSD: "LITECOIN",
  DASHUSD: "DASH",
  XRPUSD: "RIPPLE",
  BCHUSD: "BITCOIN CASH",
  DAX: "GERMAN 30",
  UK100: "UK 100",
  WS30: "WALL STREET",
  CAC: "FRANCE 40",
  ETF_XLK: "TECNOLOGÍA",
  ETF_EEM: "EMERGENTES",
  ETF_ECH: "IPSA",
  ETF_IBB: "BIOTECH",
  ETF_FXI: "CHINA 25",
  ETF_XLI: "SPDR",
  GLD: "ORO",
  SLV: "PLATA",
  EURUSD: "EUR-USD",
  GBPUSD: "GBP-USD",
  USDJPY: "USD-JPY",
  USDCLP: "USD-CLP",
  USDCAD: "USD-CAD",
  AUDUSD: "AUD-USD",
}

const transformData = data => {
  const returnData = []

  for (let i in data) {
    returnData.push({
      name: nameInstruments[data[i].name] || "",
      nemo: data[i].name,
      sell: data[i].sell,
      buy: data[i].buy,
    })
  }

  return returnData
}

const getPriceInstrument = async (priceInstrument, markets, setMarkets) => {
  if (!priceInstrument.loading) {
    markets[priceInstrument.value.toString()] = priceInstrument.instruments.body
    setMarkets(markets)
  }

  try {
    priceInstrument.loading = false
    const response = await fetch(priceInstrument.url, {
      credentials: "omit",
    })

    const data = await response.json()
    priceInstrument.instruments.body = transformData(data)
    markets[priceInstrument.value.toString()] = priceInstrument.instruments.body
    setMarkets(markets)
  } catch (err) {
    priceInstrument.loading = true
    Sentry.captureMessage("Error en la petición de los intrumentos en el Home")
    Sentry.captureException(err)
  }
}

export { dataPriceInstruments, getPriceInstrument }
