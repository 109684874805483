import React from "react"
import { FocusEvent } from "react"
import styled from "@emotion/styled"
import { Global } from "@emotion/core"

const FormGroup = styled.div``

const Label = styled.label`
  position: relative;
  cursor: pointer;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 11.4px;
  line-height: 17px;
  color: #363f41;
  width: 100%;
  clear: both;
`

const Text = styled.span`
  width: 100%;
  padding-left: 35px;
  margin-top: -2px;
  box-decoration-break: clone;
  @media (min-width: 768px) {
    padding-left: 23px;
  }
`

type Props = {
  formGroupStyle?: any
  label: string | React.ReactNode
  labelStyle?: any
  value: string
  onChange?: (event: string) => void
  onBlur?: (event: FocusEvent<any>) => void
  hasError?: boolean | ""
  errorMessage?: string
}

const CustomCheckbox = ({
  formGroupStyle,
  label,
  labelStyle,
  value,
  onChange,
  onBlur,
  hasError,
}: Props) => (
  <FormGroup css={formGroupStyle}>
    <Global
      styles={{
        "input[type='checkbox']": {
          display: "none",
        },
        ".checkmark": {
          position: "absolute",
          top: 0,
          left: 0,
          height: "29px",
          width: "29px",
          borderRadius: "3px",
          transition: "all 0.3s ease-out",
          border: "2px solid #e0e0e0",
          backgroundColor: "#ffffff",
          "@media (min-width: 768px)": {
            width: "18px",
            height: "18px",
            border: "1px solid #e8e8e8",
          },
        },
        ".checkmark::after": {
          position: "absolute",
          content: '""',
          height: "0px",
          width: "0px",
          borderRadius: "3px",
          border: "solid #62aa46",
          borderWidth: "0 3px 3px 0",
          transform: "rotate(0deg) scale(0)",
          opacity: 1,
          transition: "all 0.3s ease-out",
        },
        ".checkmark:after": {
          left: "8px",
          top: "2px",
          width: "9px",
          height: "19px",
          border: "solid white",
          borderWidth: "0 4px 4px 0",
          transform: "rotate(45deg)",
          "@media (min-width: 768px)": {
            left: "5px",
            top: "2px",
            width: "5px",
            height: "10px",
            borderWidth: "0 2px 2px 0",
          },
        },
        "input[type='checkbox']:checked ~ .checkmark": {
          backgroundColor: "#62aa46",
          borderRadius: "5px",
          transform: "rotate(0deg) scale(1)",
          opacity: 1,
        },
      }}
    />
    <Label css={labelStyle}>
      <Text className={hasError ? "error" : ""}>{label}</Text>
      <input
        type="checkbox"
        value={value}
        onChange={e => {
          const boolValue = e.target.value === "true"
          if (onChange) onChange((!boolValue).toString())
        }}
        onBlur={onBlur}
      />
      <span className="checkmark"></span>
    </Label>
  </FormGroup>
)

export default CustomCheckbox
