import React from "react"
import styled from "@emotion/styled"

const SvgWallet = styled.svg`
  transform: scale(1.3875);
`

const People = () => (
  <SvgWallet
    width="80"
    height="80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.86 56.354l1.569.1.37-5.857-1.568-.099-.37 5.856z"
      fill="url(#paint0_linearp)"
    />
    <path
      d="M48.295 50.54l.369 5.855 1.568-.099-.369-5.855-1.568.098z"
      fill="url(#paint1_linearp)"
    />
    <path
      d="M40.052 37.46c3.982 0 7.23-3.249 7.23-7.23 0-3.982-3.248-7.23-7.23-7.23-3.981 0-7.23 3.248-7.23 7.23 0 3.981 3.249 7.23 7.23 7.23zm0-12.993a5.746 5.746 0 015.763 5.763c0 3.248-2.515 5.762-5.763 5.762a5.746 5.746 0 01-5.762-5.762 5.746 5.746 0 015.762-5.763z"
      fill="url(#paint2_linearp)"
    />
    <path
      d="M73.58 49.299c0-.943-.313-1.886-.837-2.934-.524-1.048-1.467-1.781-2.62-2.2l-4.086-1.467-3.562 6.6h-.943l-3.563-6.6-3.772 1.362-.314-.629c-.733-1.257-1.886-2.2-3.353-2.724l-5.134-1.886-4.61 8.592h-1.572l-4.61-8.592-5.134 1.886c-1.467.524-2.62 1.467-3.353 2.724l-.314.63-3.772-1.363-3.563 6.6h-.943l-3.562-6.6-3.981 1.467c-1.153.419-2.096 1.152-2.62 2.2-.524.943-.838 1.991-.838 2.83 0 0-.42 3.666-.524 7.124l1.572.105c.104-3.458.524-7.02.524-7.125 0-.838.314-1.572.628-2.096.42-.628 1.048-1.257 1.781-1.467l2.83-1.047 3.352 6.286h2.83l3.352-6.286 2.41.838c-.105.524-.21 1.048-.21 1.467 0 0-.523 4.715-.733 9.325l1.572.105c.21-4.506.733-9.22.733-9.325 0-1.153.524-2.2.838-2.934.524-.943 1.362-1.676 2.515-2.096l3.877-1.362 4.4 8.173h3.458l4.4-8.173 3.877 1.362c1.048.42 1.99 1.153 2.515 2.096.419.733.838 1.781.838 2.934 0 0 .524 4.715.733 9.22l1.572-.105c-.21-4.61-.733-9.22-.733-9.22 0-.524-.105-1.048-.21-1.572l2.41-.838 3.353 6.287h2.829l3.353-6.287 2.829 1.048c.733.314 1.466.838 1.78 1.467.315.524.63 1.257.63 2.2 0 0 .419 3.562.523 7.02L74 56.214c0-3.353-.42-6.915-.42-6.915z"
      fill="url(#paint3_linearp)"
    />
    <path
      d="M68.132 51.9l.283 4.496 1.569-.099-.283-4.496-1.569.099z"
      fill="url(#paint4_linearp)"
    />
    <path
      d="M61.951 42.07c3.143 0 5.763-2.62 5.763-5.763 0-3.144-2.62-5.763-5.763-5.763s-5.763 2.62-5.763 5.763a5.746 5.746 0 005.763 5.762zm0-9.954a4.203 4.203 0 014.191 4.19 4.203 4.203 0 01-4.19 4.192 4.203 4.203 0 01-4.192-4.191 4.203 4.203 0 014.191-4.191z"
      fill="url(#paint5_linearp)"
    />
    <path
      d="M10.192 56.264l1.569.099.284-4.497-1.568-.099-.285 4.497z"
      fill="url(#paint6_linearp)"
    />
    <path
      d="M18.153 42.07c3.144 0 5.763-2.62 5.763-5.763 0-3.144-2.62-5.763-5.763-5.763s-5.762 2.62-5.762 5.763a5.746 5.746 0 005.762 5.762zm0-9.954a4.203 4.203 0 014.191 4.19 4.203 4.203 0 01-4.19 4.192 4.203 4.203 0 01-4.192-4.191 4.203 4.203 0 014.191-4.191z"
      fill="url(#paint7_linearp)"
    />
    <defs>
      <linearGradient
        id="paint0_linearp"
        x1="30.645"
        y1="56.404"
        x2="31.015"
        y2="50.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint1_linearp"
        x1="48.479"
        y1="53.467"
        x2="50.048"
        y2="53.368"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint2_linearp"
        x1="40.052"
        y1="23"
        x2="40.052"
        y2="37.459"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint3_linearp"
        x1="40"
        y1="38.821"
        x2="40"
        y2="56.424"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint4_linearp"
        x1="68.274"
        y1="54.148"
        x2="69.842"
        y2="54.049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint5_linearp"
        x1="61.951"
        y1="30.544"
        x2="61.951"
        y2="42.069"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint6_linearp"
        x1="10.976"
        y1="56.313"
        x2="11.261"
        y2="51.817"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
      <linearGradient
        id="paint7_linearp"
        x1="18.153"
        y1="30.544"
        x2="18.153"
        y2="42.069"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
    </defs>
  </SvgWallet>
)

export default People
