import React from "react"
import styled from "@emotion/styled"

const SvgWallet = styled.svg`
  transform: scale(0.65);
`

const Wallet = () => (
  <SvgWallet width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.494 47.076a2.96 2.96 0 002.956-2.955 2.959 2.959 0 00-2.956-2.955 2.959 2.959 0 00-2.955 2.955 2.96 2.96 0 002.955 2.955zm13.935 3.488H53.67l-5.745-6.443 5.745-6.443H70.43v12.886zm1.798-16.481h-1.199V19.398A1.8 1.8 0 0069.23 17.6h-3.446v-6.443a1.8 1.8 0 00-1.798-1.798h-8.378c-.725 0-1.375.433-1.657 1.1a1.795 1.795 0 001.657 2.496h6.58V17.6H52.135c-.724 0-1.374.432-1.656 1.1a1.794 1.794 0 00.159 1.692c.334.504.894.804 1.497.804h15.297v12.887H52.865c-.513 0-1.003.218-1.342.601l-7.351 8.24a1.799 1.799 0 000 2.394l7.35 8.24c.342.383.832.602 1.343.602h14.567v12.886H31.304a1.796 1.796 0 00-1.498 2.792c.334.504.894.803 1.498.803H69.23a1.8 1.8 0 001.798-1.797V54.16h1.199a1.8 1.8 0 001.798-1.798V35.88a1.8 1.8 0 00-1.798-1.797zM51.28 6.143a1.796 1.796 0 00-2.355.958L21.791 71.504c-.187.442-.19.931-.009 1.376A1.787 1.787 0 0023.448 74c.725 0 1.376-.431 1.658-1.1L52.24 8.497a1.797 1.797 0 00-.958-2.354zM18.423 67.046H15.29a5.701 5.701 0 01-5.694-5.694V20.735c.704.3 1.5.461 2.287.461h27.372c.726 0 1.376-.432 1.657-1.1a1.794 1.794 0 00-.159-1.692 1.794 1.794 0 00-1.498-.804H11.878a2.285 2.285 0 01-2.282-2.281v-.077a2.29 2.29 0 012.287-2.287h30.844a1.796 1.796 0 001.499-2.792 1.794 1.794 0 00-1.499-.804H11.883C8.635 9.36 6 11.994 6 15.232v46.12c0 5.123 4.168 9.29 9.29 9.29h3.134c.725 0 1.375-.432 1.656-1.1a1.794 1.794 0 00-.159-1.693 1.792 1.792 0 00-1.497-.803z"
      fill="url(#paint0_linearw)"
    />
    <defs>
      <linearGradient
        id="paint0_linearw"
        x1="40.012"
        y1="6"
        x2="40.012"
        y2="74"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
    </defs>
  </SvgWallet>
)

export default Wallet
